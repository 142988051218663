<template>
  <div>
    <top-nav :showLogo="false" :title="$t('充值')"></top-nav>
    <div class="top-bg">
      <div class="title">
        {{$t('充值')}}
      </div>
      <img src="../../assets/image/rwa/top-bg.png">
    </div>
    <div class="container">
      <div class="input-cont">
        <div class="title">{{$t('转入地址')}}</div>
        <div class="input-div">
          <input type="text" :placeholder="$t('请输入')"/>
          <img class="empty-icon" src="../../assets/image/rwa/empty-icon.png">
        </div>
      </div>
      <div class="input-cont">
        <div class="title" style="color: #666">BUR{{$t('余额')}}:10,000.00</div>
        <div class="input-div">
          <input type="text" :placeholder="$t('请输入')"/>
          <span>BUR</span>
        </div>
      </div>

      <div class="actual-acquisition">
        <div style="float: left">实际获得</div>
        <div style="float: right;color: #333">12,223.00 种草值</div>
      </div>


      <div class="bottom-cont">
        <div class="order-but">
          <img class="order-icon" src="../../assets/image/rwa/order-icon2.png">
        </div>
        <div class="confirm-but">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'

export default {
  name: "recharge",
  components: {TopNav},
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped lang="less">
.top-bg {
  position: relative;

  .title {
    position: absolute;
    left: 2.29rem;
    bottom: 2.7rem;
    font-size: 1.43rem;
    color: #E7BD71;
  }

  img {
    width: 100%;
  }
}

.container {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  bottom: 0;
  padding: 0.9rem 1rem;
  background: linear-gradient(180deg, #E8BD70 0%, #EDD185 100%);
  border-radius: 1rem 1rem 0 0;
}

.input-cont {
  padding: 1rem 1.35rem;
  margin-top: 1.5rem;
  background: #FFF8EA;
  border-radius: 0.71rem;

  .title {
    font-size: 0.79rem;
    color: #999999;
  }

  .input-div {
    text-align: right;
    line-height: 1.29rem;

    input {
      width: 80%;
      text-align: right;
      font-size: 1rem;
      color: #333333;
      border: none;
      background-color: transparent;
      margin-right: 1.5rem;
    }

    input::-webkit-input-placeholder {
      color: #999999;
    }

    span {
      font-size: 0.79rem;
      color: #666;
    }

    .empty-icon {
      float: right;
      width: 1.29rem;
    }
  }
}

.actual-acquisition {
  width: 90%;
  margin: 0.68rem auto;
  font-size: 0.86rem;
  color: #9E7A37;
  overflow: hidden;
}

.bottom-cont {
  margin-top: 22vh;

  .order-but {
    width: 25%;
    display: inline-block;
    padding: 0.79rem 0;
    text-align: center;
    background: #FFDEA3;
    border-radius: 2rem;
    .order-icon {
      height: 1.5rem;
    }
  }
  .confirm-but{
    width: 70%;
    float: right;
    display: inline-block;
    background: #FFFFFF;
    border-radius:2rem;
    font-size: 1.29rem;
    color: #70521B;
    line-height: 1.5rem;
    text-align: center;
    padding: 0.79rem 0;
  }
}
</style>